/* eslint-disable @typescript-eslint/prefer-for-of */
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';


export class ImageDetail {
  fpso: string;
  tier1: string;
  tier2: string;
  tier3: string;
  itemType: string;

  constructor(data: any) {
    this.fpso = data.FPSO;
    this.tier1 = data.Tier1;
    this.tier2 = data.Tier2;
    this.tier3 = data.Tier3;
    this.itemType = data['Item Type'];
  }
}

@Injectable({
  providedIn: 'root'
})
export class DomainService extends BaseService {

  imageDetails: ImageDetail[] = [];

  constructor(private httpService: HttpClient) {
    super(httpService);
  }

  getSectors() {
    return this.get('sectors').toPromise();
  }

  getPositionsSectors(idSector) {
    return this.get(`positions-sectors?sector=${idSector}`).toPromise();
  }

  getIaModels() {
    return this.get(`ia-models?isDeleted=false`).toPromise();
  }

  async getFpso() {
    await this.getImageDetails();
    return await this.groupByData(this.imageDetails, 'fpso');
  }

  async getTier1(fpso: any) {
    await this.getImageDetails();
    const docs = this.imageDetails.filter(p => p.fpso === fpso);
    return await this.groupByData(docs, 'tier1');
  }

  async getTier2(fpso: any, tier1: any) {
    await this.getImageDetails();
    let docs = [];
    for (let i = 0; i < tier1.length; i++) {
      docs = docs.concat(this.imageDetails.filter(p => p.tier1 === tier1[i] && p.fpso === fpso));
    }
    return await this.groupByData(docs, 'tier2');
  }

  async getTier3(fpso: any, tier2: any) {
    await this.getImageDetails();
    let docs = [];
    for (let i = 0; i < tier2.length; i++) {
      docs = docs.concat(this.imageDetails.filter(p => p.tier2 === tier2[i] && p.fpso === fpso));
    }
    return await this.groupByData(docs, 'tier3');
  }

  async getItemsTypeForFpso(fpso: any) {
    await this.getImageDetails();
    const docs = this.imageDetails.filter(p => p.fpso === fpso);
    return await this.groupByData(docs, 'itemType');
  }

  private async getImageDetails() {
    if (this.imageDetails.length === 0) {
      const result: any = await this.httpService.get('/assets/json/cogmo.json').toPromise();
      this.imageDetails = result.map((item) => new ImageDetail(item));
    }
  }

  private async groupByData(list: any[], prop: string) {
    const doc = _.groupBy(list, prop);
    const newList: any[] = [];
    for (const key of Object.keys(doc)) {
      if (key) {
        newList.push({
          id: key,
          name: key
        });
      }
    }
    return _.orderBy(newList, ['name'], ['asc']);;
  }
}
