import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-search-auto-complete',
  templateUrl: './search-auto-complete.component.html',
  styleUrls: ['./search-auto-complete.component.scss'],
})
export class SearchAutoCompleteComponent implements OnInit {

  @Input()
  isFilter: boolean;


  @Input()
  title = '';

  @Input()
  options: any[] = [];

  @Input()
  optionSelected = [];

  optionsOrigin: any[] = [];


  constructor(
    private modalController: ModalController,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.optionsOrigin = this.options;
  }

  filter(ev) {
    const text = ev.detail.value ? ev.detail.value : '';
    if (text.length > 0) {
      this.options = this.optionsOrigin.filter(p => p.name.toUpperCase().includes(text.toUpperCase()));
    } else {
      this.options = this.optionsOrigin;
    }
  }

  async selectOption(item: string) {
    if (!this.isFilter) {
      this.optionSelected[0] = item;
      await this.modalController.dismiss(this.optionSelected);
    } else {
      if (this.title.includes('FPSO')) {
        this.optionSelected[0] = item;
      } else {
        const found = this.optionSelected.find(element => element === item);
        if (found !== undefined) {
          const index = this.optionSelected.indexOf(found);
          this.optionSelected.splice(index, 1);
        } else {
          this.optionSelected.push(item);
        }
      }
    }
  }

  checkMark(item) {
    let found = '';
    if (this.title.includes('Model')) {
      found = this.optionSelected.find(element => element === item);
    } else {
      found = this.optionSelected.find(element => element === item.name);
    }
    if (found !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  async close() {
    //this.selectOption(null);
    this.optionSelected = [];
    await this.modalController.dismiss(this.optionSelected);
  }

  async closeSearch() {
    if (!this.optionSelected) {
      await this.toastService.toastError('Select an item');
      return false;
    }
    await this.modalController.dismiss(this.optionSelected);
  }

}
