import { MenuController, Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Capacitor } from '@capacitor/core';

interface MenuData {
  title: string;
  icon: string;
  url: string;
  roles: any[];
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  titleLogout = 'Log out';

  isDesktop: boolean;

  user: any;

  menuUser: Array<any> = [];
  menu: Array<any> = [
    {
      title: 'Gallery',
      url: '/gallery',
      icon: '/assets/icons/ico-gallery.svg',
      roles: []
    },
    {
      title: 'Offline Gallery',
      url: '/gallery-offline',
      icon: '/assets/icons/ico-offline-gallery.svg',
      roles: []
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: '/assets/icons/ico-config.svg',
      roles: []
    },
    {
      title: 'Trash',
      url: '/trash',
      icon: '/assets/icons/ico-trash.svg',
      roles: []
    },
    {
      title: this.titleLogout,
      url: '/logout',
      icon: '/assets/icons/ico-logout.svg',
      roles: []
    }
  ];

  currentRoute = this.router.url;

  constructor(
    private router: Router,
    private menuController: MenuController,
    private authService: AuthService,
    private platform: Platform
  ) {
    this.router.events.subscribe((event) => {
      this.user = this.authService.getUserData();
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
      }
    });
  }

  ngOnInit() {
    if (Capacitor.getPlatform()==='web') {
      this.isDesktop = true;
    }
  }

  async goTo(menu: MenuData) {
    if (menu.title === this.titleLogout) {
      await this.logout();
    } else {
      await this.router.navigateByUrl(menu.url);
    }
    if (await this.menuController.isOpen()) {
      await this.menuController.close();
    }
  }

  async logout() {
    await this.authService.clearAuthorizationToken();
    await this.router.navigate(['login']);
  }

  async loadMenu() {
    // this.menuUsuario = this.menu.filter(m => m.roles.filter(r => roles.filter(ru => ru === r).length > 0).length > 0);
    this.menuUser = this.menu;
  }

}
