import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController) {
  }

  async toast(message: string | any, color?: string, position: 'top' | 'bottom' | 'middle' = 'top',
              duration: number = 5000, cssClass: string = 'toast-class') {
    const t = await this.toastController.create({
      message,
      duration,
      color,
      position,
      keyboardClose: true,
      animated: true,
      cssClass,
      buttons: ['X']
    });
    await t.present();
  }

  async toastSuccess(message: string) {
    await this.toast(message, 'success');
  }

  async toastError(message: string) {
    await this.toast(message, 'danger');
  }

  async toastWarning(message: string) {
    await this.toast(message, 'warning');
  }

  async toastDark(message: string) {
    await this.toast(message, 'dark');
  }
}
