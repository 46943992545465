/* eslint-disable no-var */
/* eslint-disable max-len */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, } from '@angular/forms';
import { IonDatetime, ModalController } from '@ionic/angular';
import { format, parseISO, getDate, getMonth, getYear } from 'date-fns';
import * as moment from 'moment';
import { DomainService } from '../../services/domain.service';
import { TypeSearch } from '../../../shared/enum/typeSearch.enum';
import { SearchAutoCompleteComponent } from '../../../shared/components/search-auto-complete/search-auto-complete.component';
import { filter } from 'rxjs/operators';
import { parseDate } from '@mobiscroll/angular';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  // @ViewChild(IonDatetime, { static: true }) datetime: any;

  @Input() unit: string;
  @Input() module: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() mark: Array<string>;

  maskOptions = [
    { val: 'first mask', isChecked: false },
    { val: 'second mask', isChecked: false },
    { val: 'third mask', isChecked: false },
  ];

  unitSelected = 'Unit';
  moduleSelected = 'Module';
  masksSelecteds: Array<string> = [];

  critic: Array<any> = [
    { val: 'second mask', isChecked: false },
    { val: 'third mask', isChecked: false }
  ];
  rustMin: number;
  rustMax: number;
  fpso: string;
  tier1: string;
  tier2: string;
  tier3: string;
  itemType: string;

  aiModelOptions: Array<any> = ['first option', 'second option', 'third option',];
  rustMinOptions: Array<any> = [];
  rustMaxOptions: Array<any> = [];
  criticOptions: Array<any> = ['Sim', 'Não',];
  fpsoOptions: Array<any> = ['setOptionFpso option', 'setOptionFpso option', 'setOptionFpso option',];
  tier1Options: Array<any> = ['first option', 'second option', 'third option',];
  tier2Options: Array<any> = ['first option', 'second option', 'third option',];
  tier3Options: Array<any> = ['first option', 'second option', 'third option',];
  itemTypeOptions: Array<any> = ['first option', 'second option', 'third option',];

  aiModelSelected = '';
  rustMinSelected = '';
  rustMaxSelected = '';
  criticSelected = '';
  fpsoSelected = '';
  tier1Selected = '';
  tier2Selected = '';
  tier3Selected = '';
  itemTypeSelected = '';
  dateValue1 = '';
  dateValue2 = '';
  formatDateValue1 = '';
  formatDateValue2 = '';

  foco: string;

  fpsoDocs: any[] = [];
  tier1Docs: any[] = [];
  tier2Docs: any[] = [];
  tier3Docs: any[] = [];
  iaDocs: any[] = [];
  itemsTypeDocs: any[] = [];
  imageDetail = {
    fpso: [],
    tier1: [],
    tier2: [],
    tier3: [],
    ia: [],
    itemType: [],
    problem: '',
    critical: false,

    // name: '',
    // image: '',
    // latitude: '',
    // longitude: '',
    // altitude: '',
    // motionApha: 0,
    // motionBeta: 0,
    // motionGamma: 0,
    // azimute: '',
    // 'percent_corrosion': '',
    // 'percent_staining': '',
  };

  imageDetailErro = {
    fpso: false,
    tier1: false,
    tier2: false,
    tier3: false,
    ia: false,
    itemType: false,
    problem: false,
  };

  applyDisabled = false;

  listAiModels: any[] = [];

  constructor(
    public modalController: ModalController,
    private build: FormBuilder,
    private domainService: DomainService,) {
    console.log(this.imageDetail.tier1.length);
  }

  // confirm() {
  //   this.datetime.nativeEl.confirm();
  // }

  // reset() {
  //   this.datetime.nativeEl.reset();
  // }

  formatDate(value: string, set: number) {
    if (set === 1) {
      this.formatDateValue1 = format(parseISO(value), 'yyyy-MM-dd');
    } else {
      this.formatDateValue2 = format(parseISO(value), 'yyyy-MM-dd');
    }
    return format(parseISO(value), 'MM/dd/yyyy');
  }
  async clearDataImagemDetail(typeSearch: TypeSearch) {
    switch (typeSearch) {
      case TypeSearch.FPSO:
        this.imageDetail.tier1 = [];
        this.imageDetail.tier2 = [];
        this.imageDetail.tier3 = [];
        this.imageDetail.itemType = [];
        break;
      case TypeSearch.Tier1:
        this.imageDetail.tier2 = [];
        this.imageDetail.tier3 = [];
        break;
      case TypeSearch.Tier2:
        this.imageDetail.tier3 = [];
        break;
      default:
        break;
    }
  }
  async selectTier1() {
    this.tier1Docs = await this.domainService.getTier1(this.imageDetail.fpso[0]);
    this.imageDetail.tier2 = [];
    this.imageDetail.tier3 = [];
    const returnSelection = await this.openModalSearch(this.tier1Docs, 'Choose an Tier 1', this.imageDetail.tier1);
    if (returnSelection !== this.imageDetail.tier1) {
      this.imageDetail.tier1 = returnSelection;
      await this.clearDataImagemDetail(TypeSearch.Tier1);
    }
  }

  async selectTier2() {
    this.tier2Docs = await this.domainService.getTier2(this.imageDetail.fpso[0], this.imageDetail.tier1);
    this.imageDetail.tier3 = [];
    const returnSelection = await this.openModalSearch(this.tier2Docs, 'Choose an Tier 2', this.imageDetail.tier2);
    if (returnSelection !== this.imageDetail.tier2) {
      this.imageDetail.tier2 = returnSelection;
      await this.clearDataImagemDetail(TypeSearch.Tier2);
    }
  }

  async selectTier3() {
    this.tier3Docs = await this.domainService.getTier3(this.imageDetail.fpso[0], this.imageDetail.tier2);
    this.imageDetail.tier3 = await this.openModalSearch(this.tier3Docs, 'Choose an Tier 3', this.imageDetail.tier3);
  }

  async selectIaModel() {
    this.iaDocs = await this.domainService.getIaModels();
    this.imageDetail.ia = await this.openModalSearch(this.iaDocs, 'Choose an Ia Model', this.imageDetail.ia);
  }

  async selectItemType() {
    this.itemsTypeDocs = await this.domainService.getItemsTypeForFpso(this.imageDetail.fpso[0]);
    this.imageDetail.itemType = await this.openModalSearch(this.itemsTypeDocs, 'Choose an Item Type', this.imageDetail.itemType);
  }

  async selectFpso() {
    const returnSelection = await this.openModalSearch(this.fpsoDocs, 'Choose an FPSO', this.imageDetail.fpso);
    this.imageDetail.tier1 = [];
    this.imageDetail.tier3 = [];
    this.imageDetail.tier2 = [];
    this.imageDetail.tier3 = [];
    this.imageDetail.itemType = [];
    if (returnSelection !== this.imageDetail.fpso) {
      this.imageDetail.fpso = returnSelection;
      await this.clearDataImagemDetail(TypeSearch.FPSO);
    }
  }

  async openModalSearch(options: any[], title: string, optionSelected: any) {
    const isFilter = true;
    const modal = await this.modalController.create({
      component: SearchAutoCompleteComponent,
      swipeToClose: true,
      componentProps: {
        options,
        title,
        optionSelected,
        isFilter
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async loadDomains() {
    this.fpsoDocs = await this.domainService.getFpso();
  }

  // async verifyDataImagemDetail() {
  //   this.imageDetailErro.fpso = this.imageDetail.fpso.length!==0 ? false : true;
  //   this.imageDetailErro.tier1 = this.imageDetail.tier1.length!==0 ? false : true;
  //   this.imageDetailErro.tier2 = this.imageDetail.tier2.length!==0 ? false : true;
  //   this.imageDetailErro.tier3 = this.imageDetail.tier3.length!==0 ? false : true;
  //   this.imageDetailErro.ia = this.imageDetail.ia.length!==0 ? false : true;
  //   this.imageDetailErro.itemType = this.imageDetail.itemType.length!==0 ? false : true;
  //   this.imageDetailErro.problem = false; // this.imageDetail.problem ? false : true;
  //   for (const prop of Object.keys(this.imageDetailErro)) {
  //     if (this.imageDetailErro[prop] === true) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  isDateEnabled(dateIsoString: string) {
    const date = new Date(dateIsoString);
    if (getDate(date) === 1 && getMonth(date) === 0 && getYear(date) === 2022) {
      // Disables January 1, 2022.
      return false;
    }
    return true;
  }

  inputBlur(event) {
  }

  async ngOnInit() {
    this.checkMarksSelecteds();
    this.calcRust();
    await this.loadDomains();
  }

  calcRust(min?) {
    if (min) {
      min = min.replace('%', '');
      for (let i = min; i <= 100; i++) {
        this.rustMaxOptions.push(`${i}%`);
      }
    } else {
      for (let i = 0; i <= 100; i++) {
        this.rustMinOptions.push(`${i}%`);
      }
    }
  }

  clickSelect(event) {
    if (this.foco === event) {
      this.foco = '';
    } else {
      this.foco = event;
    }
  }

  date1Change(event) {
  }

  setOptionAiModel(model) {
    this.aiModelSelected = model;
    this.foco = '';
  }

  setOptionRustMin(rustMin) {
    this.rustMinSelected = rustMin;
    this.rustMaxSelected = '';
    this.foco = '';
    this.calcRust(rustMin);
  }

  setOptionRustMax(rustMax) {
    this.rustMaxSelected = rustMax;
    this.foco = '';
  }

  setOptionCritic(critic) {
    this.criticSelected = critic;
    this.foco = '';
  }

  setOptionFpso(fpso) {
    this.fpsoSelected = fpso;
    this.foco = '';
  }

  setOptionTier1(tier1) {
    this.tier1Selected = tier1;
    this.foco = '';
  }

  setOptionTier2(tier2) {
    this.tier2Selected = tier2;
    this.foco = '';
  }

  setOptionTier3(tier3) {
    this.tier3Selected = tier3;
    this.foco = '';
  }

  setOptionItemType(itemType) {
    this.itemTypeSelected = itemType;
    this.foco = '';
  }

  setOptionsMask(mask) {
    this.masksSelecteds = [mask];
    this.foco = '';
  }

  checkboxChange(event) {
  }

  checkMarksSelecteds() {
    this.masksSelecteds = [];
    this.maskOptions.forEach(element => {
      for (const value of this.mark) {
        if (element.val === value) {
          if (element.isChecked === false) {
            element.isChecked = true;
            this.masksSelecteds.push(element.val);
          }
        }
      }
    });
  }

  selectMarks() {
    this.masksSelecteds = [];
    this.maskOptions.forEach(element => {
      if (element.isChecked === true) {
        this.masksSelecteds.push(element.val);
      }
    });
    this.foco = '';
  }

  deleteMask(mask) {
    const i = this.masksSelecteds.indexOf(mask);
    this.masksSelecteds.splice(i, 1);
    this.maskOptions.forEach(element => {
      if (element.val === mask) {
        element.isChecked = false;
      }
    });
    this.foco = '';
  }

  apply() {
    const imageDetailData = {
      fpso: this.imageDetail.fpso.join(','),
      tier1: this.imageDetail.tier1.join(','),
      tier2: this.imageDetail.tier2.join(','),
      tier3: this.imageDetail.tier3.join(','),
      ia: this.imageDetail.ia.join(','),
      itemType: this.imageDetail.itemType.join(','),
      problem: this.imageDetail.problem,
      critical: this.imageDetail.critical,
    };
    let filterImageData = '';
    for (var prop in imageDetailData) {
      if (Object.prototype.hasOwnProperty.call(imageDetailData, prop)) {
        if (imageDetailData[prop]) {
          filterImageData += prop + '=' + imageDetailData[prop] + '&';
        }
      }
    }
    if (this.formatDateValue1) {
      filterImageData += 'startDate=' + this.formatDateValue1 + '&';
    }
    if (this.formatDateValue2) {
      filterImageData += 'endtDate=' + this.formatDateValue2 + '&';
    }
    if (this.rustMinSelected) {
      filterImageData += 'minCorrosion=' + this.rustMinSelected.substring(0, this.rustMinSelected.length - 1) + '&';
    }
    if (this.rustMaxSelected) {
      filterImageData += 'maxCorrosion=' + this.rustMaxSelected.substring(0, this.rustMaxSelected.length - 1) + '&';
    }
    filterImageData = filterImageData.substring(0, filterImageData.length - 1);
    this.modalController.dismiss(filterImageData);
  }

}
