import { AnimationController, Animation } from '@ionic/angular';

export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  const duration = 700;
  const animationCtrl = new AnimationController();

  const enteringAnimation = animationCtrl.create()
    .addElement(opts.enteringEl)
    .duration(duration)
    .easing('ease-in')
    .fromTo('opacity', 0, 1);

  const leavingAnimation = animationCtrl.create()
    .addElement(opts.leavingEl)
    .duration(duration)
    .easing('ease-out')
    .fromTo('opacity', 1, 0);

  return animationCtrl.create().addAnimation([enteringAnimation, leavingAnimation]);

};
