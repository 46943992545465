import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./core/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./core/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./modules/gallery/gallery.module').then(m => m.GalleryPageModule)
  },
  {
    path: 'gallery-offline',
    loadChildren: () => import('./modules/gallery-offline/gallery-offline.module').then(m => m.GalleryOfflinePageModule)
  },
  {
    path: 'trash',
    loadChildren: () => import('./modules/trash/trash.module').then( m => m.TrashPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'detail-image',
    loadChildren: () => import('./modules/detail-image/detail-image.module').then( m => m.DetailImagePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
