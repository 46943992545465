/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';

const DB_NAME_KEY = 'db_cogmo';


export class ImageModel {
  id: number;
  name: string;
  image: string;
  fpso: any;
  tier1: any;
  tier2: any;
  tier3: any;
  ia: any;
  itemType: any;
  critical: any;
  latitude: string;
  longitude: string;
  altitude: string;
  motionAlpha: any;
  motionBeta: any;
  motionGamma: any;
  azimute: any;
}


@Injectable({
  providedIn: 'root'
})
export class SqliteService {

  private storage: SQLiteObject;
  imageList = new BehaviorSubject([]);
  private isDbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private sqlite: SQLite,
  ) { }

  async init() {
    try {
      await this.openDb();
      await this.createTableImages();
      // await this.deleteAllImage();
    } catch (error) {
      console.log(error);
    }
  }

  async openDb() {
    return await new Promise((resolve, reject) => {
      try {
        this.sqlite.create({
          name: 'db_cogmo.db',
          location: 'default'
        }).then((db: SQLiteObject) => {
          this.storage = db;

          resolve(true);
        });
      } catch (e) {

        reject(e);
      }
    });
  }

  dbState() {
    return this.isDbReady.asObservable();
  }

  async createTableImages() {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.executeSql('CREATE TABLE IF NOT EXISTS images (id INTEGER PRIMARY KEY AUTOINCREMENT, name TEXT, image TEXT, fpso TEXT, tier1 TEXT, tier2 TEXT, tier3 TEXT, ia TEXT, itemType TEXT, critical TEXT, latitude TEXT, longitude TEXT, altitude TEXT, motionApha TEXT, motionBeta TEXT, motionGamma TEXT, azimute TEXT)')
          .then(res => {
            resolve(true);
          }).catch((e) => {
            resolve(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  async addImage(name: string, image: string, fpso: string, tier1: string, tier2: string, tier3: string, ia: string, itemType: string, critical: boolean, latitude: string, longitude: string, altitude: string, motionAlpha: any, motionBeta: any, motionGamma: any, azimute: any) {
    return await new Promise((resolve, reject) => {
      try {
        const data = [name, image, fpso, tier1, tier2, tier3, ia, itemType, critical, latitude, longitude, altitude, motionAlpha, motionBeta, motionGamma, azimute];

        this.storage.executeSql('INSERT INTO images (name, image, fpso, tier1, tier2, tier3, ia, itemType, critical, latitude, longitude, altitude, motionApha, motionBeta, motionGamma, azimute) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)', data).then(res => {
          resolve(true);
        }).catch((e) => {
          resolve(e);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async getImages(): Promise<any> {
    return await new Promise((resolve, reject) => {
      try {
        this.storage.executeSql('SELECT * FROM images', []).then(res => {
          const items: ImageModel[] = [];

          if (res.rows.length > 0) {
            for (let i = 0; i < res.rows.length; i++) {
              items.push({
                id: res.rows.item(i).id,
                name: res.rows.item(i).name,
                image: res.rows.item(i).image,
                fpso: res.rows.item(i).fpso,
                tier1: res.rows.item(i).tier1,
                tier2: res.rows.item(i).tier2,
                tier3: res.rows.item(i).tier3,
                ia: res.rows.item(i).ia,
                itemType: res.rows.item(i).itemType,
                critical: res.rows.item(i).critical,
                latitude: res.rows.item(i).latitude,
                longitude: res.rows.item(i).longitude,
                altitude: res.rows.item(i).altitude,
                motionAlpha: res.rows.item(i).motionAlpha,
                motionBeta: res.rows.item(i).motionBeta,
                motionGamma: res.rows.item(i).motionGamma,
                azimute: res.rows.item(i).azimute
              });
            }
          }
          resolve(items);
        }).catch((e) => {
          resolve(e);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async getImageById(id): Promise<any> {
    return await new Promise((resolve, reject) => {
      try {
        return this.storage.executeSql('SELECT * FROM images WHERE id = ?', [id]).then(res => {
          if (res.rows.length > 0) {
            resolve(res.rows.item(0));
          } else {
            resolve(null);
          }
        }).catch((e) => {
          resolve(e);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async deleteImage(id) {
    return await new Promise(async (resolve, reject) => {
      try {
        return await this.storage.executeSql('DELETE FROM images WHERE id = ?', [id]).then(res => {
          resolve(true);
        }).catch((e) => {
          resolve(e);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async deleteAllImage() {
    return await new Promise((resolve, reject) => {
      try {
        return this.storage.executeSql('DELETE FROM images', []).then(res => {
          resolve(true);
        }).catch((e) => {
          resolve(e);
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}
