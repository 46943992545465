/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  store: any;
  isService = false;
  platform: string;

  constructor() { }

  async checkNetwork(): Promise<boolean> {
    try {
      const status = await Network.getStatus();
      return status.connected;
    } catch (e) {
      return false;
    }
  }

}
