/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ToastService} from '../services/toast.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getAuthorizationToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && this.router.url !== '/login') {
          this.authService.clearAuthorizationToken();
          this.toastService.toastError('Sessão expirada, por favor realize login novamente.');
          this.router.navigate(['/login']);
        }
        return throwError(err);
      })
    );
  }
}
