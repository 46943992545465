import { Component, Input, Output, OnInit, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { IonToggle } from '@ionic/angular';
import { ImageService } from '../../services/image.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss'],
})
export class HeaderDefaultComponent implements OnInit, OnDestroy {

  @ViewChild('syncUser') syncUser: IonToggle;

  @Input() desktop: boolean;
  @Input() setting: boolean;
  @Output() uploadImage = new EventEmitter<any>();
  @Input() userData: any;

  valueToggle = true;

  user: any;

  constructor(
    public imageService: ImageService,
    private authService: AuthService
    ) { }


  async ionViewWillEnter() {
    this.user = this.userData || this.authService.getUserData();
  }

  ngOnInit() {
    this.user = this.userData || this.authService.getUserData();
    this.imageService.syncOffline$.subscribe((sync) => {

      setTimeout(() => {
        this.valueToggle = sync;
      }, 100);
    });
  }

  ngOnDestroy(): void { }

  upload(){
    this.uploadImage.emit();
  }

  async onClick(event) {
    this.imageService.setSyncOffline();
  }
}
