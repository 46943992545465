import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
})
export class ViewImageComponent implements OnInit {

  @Input()
  img: any;
  isGalleryOnline: boolean;

  src = '';

  constructor(
    private modalController: ModalController,
    private router: Router,
    public loadingService: LoadingService,
    public toastService: ToastService
  ) {}

  ngOnInit() {
    if(this.isGalleryOnline){
      this.src = this.img.originalPath;
    }else{
      this.src = this.img.image;
    }
  }

  async close() {
    await this.modalController.dismiss('close');
  }

  async navToDetail() {
    this.router.navigate(['/detail-image']);
    await this.modalController.dismiss();
  }

  async changeImageMask() {
    if (this.src === this.img.originalPath) {
      this.src = this.img.pathMasked;
    } else {
      this.src = this.img.originalPath;
    }
  }

  async ionImgWillLoad() {
    // await this.loadingService.presentLoading('Await');
  }

  async ionImgDidLoad() {
    // setTimeout(async () => {
    //   await this.loadingService.dismiss();
    // }, 200);
  }

  async ionError() {
    setTimeout(async () => {
      await this.loadingService.dismiss();
      await this.toastService.toastError('Error loading image');
      await this.modalController.dismiss();
    }, 200);
  }

}
