/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { NetworkService } from './network.service';
import { BehaviorSubject } from 'rxjs';
import { SqliteService } from './sqlite.service';
import { LoadingService } from './../../shared/services/loading.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService extends BaseService {

  imagemDetailSelected: any = null;

  imagemDetailLastSave: any = null;

  isSending: boolean = false;

  // public syncOffline$: BehaviorSubject<any> = new BehaviorSubject(true);
  public syncOffline$: BehaviorSubject<any> = new BehaviorSubject(
    localStorage.getItem('syncEnabled') ? JSON.parse(localStorage.getItem('syncEnabled')) : true
  );


  constructor(
    private httpService: HttpClient,
    private sqliteService: SqliteService,
    private networkService: NetworkService,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) {
    super(httpService);
  }

  async setImageLastSave(data: any) {
    this.imagemDetailLastSave = data;
  }

  async getImageLastSave() {
    return this.imagemDetailLastSave;
  }

  async setImageSelected(data: any) {
    this.imagemDetailSelected = data;
  }

  async getImageSelected() {
    return this.imagemDetailSelected;
  }

  async resetImageSelected() {
    this.imagemDetailSelected = null;
  }

  async resetImageLastSaved() {
    this.imagemDetailLastSave = null;
  }

  async setSyncOffline() {
    const syncOffline = this.syncOffline$.getValue();
    this.syncOffline$.next(!syncOffline);
    localStorage.setItem('syncEnabled', `${!syncOffline}`);
    if (!syncOffline) {
      //await this.loadingService.presentLoading('Sending images...');
      await this.startSynchronizationJob();
      //await this.loadingService.dismiss();
    }
  }

  async getSyncOffline(): Promise<boolean> {
    return this.syncOffline$.getValue();
  }

  async listImagens(isDeleted: boolean = false, page: number) {
    const syncOffline = this.syncOffline$.getValue();
    if (syncOffline) {
      return this.get(`images?isDeleted=${isDeleted}&page=${page}`).toPromise();
    } else {
      return [];
    }
  }

  async listImagensFilter(isDeleted: boolean = false, params: string, page: number) {
    const syncOffline = this.syncOffline$.getValue();
    if (syncOffline) {
      return this.get(`images?isDeleted=${isDeleted}&${params}&page=${page}`).toPromise();
    } else {
      return [];
    }
  }


  async insertImageOnline(data: any) {
    return await this.post(`upload-image`, data).toPromise();
  }

  async updateImageOnline(id: string, data: any) {
    return this.put(`images/${id}`, data).toPromise();
  }

  async insertImageOffline(data: any) {
    await this.sqliteService.addImage(data.name, data.image, data.fpso, data.tier1, data.tier2, data.tier3, data.ia, data.itemType, data.critical, data.latitude, data.longitude, data.altitude, data.motionApha, data.motionBeta, data.motionGamma, data.azimute);
  }

  async listImagesOffline(): Promise<any[]> {
    return await this.sqliteService.getImages();
  }

  private async sendImageOffline() {
    if (this.isSending) {
      return;
    }
    this.isSending = true;
    if (!this.syncOffline$.getValue()) {
      return;
    }

    try {
      const isNetwork = await this.networkService.checkNetwork();
      if (isNetwork) {
        const listImagesOffline = await this.listImagesOffline();
        if (listImagesOffline.length > 0) {
          const dataImagem = {
            name: listImagesOffline[0].name,
            image: listImagesOffline[0].image,
            fpso: listImagesOffline[0].fpso,
            tier1: listImagesOffline[0].tier1,
            tier2: listImagesOffline[0].tier2,
            tier3: listImagesOffline[0].tier3,
            ia: listImagesOffline[0].ia,
            itemType: listImagesOffline[0].itemType,
            critical: (listImagesOffline[0].critical === 'true'),
            latitude: listImagesOffline[0].latitude,
            longitude: listImagesOffline[0].longitude,
            altitude: listImagesOffline[0].altitude,
            motionApha: listImagesOffline[0].motionApha,
            motionBeta: listImagesOffline[0].motionBeta,
            motionGamma: listImagesOffline[0].motionGamma,
            azimute: listImagesOffline[0].azimute,
          };
          await this.insertImageOnline(dataImagem);
          await this.sqliteService.deleteImage(listImagesOffline[0].id);
        }
      }
    } catch (error) {
      console.log('error: ', error.message);
    }
    finally {
      this.isSending = false;
    }
  }

  startSynchronizationJob() {
    setInterval(async () => {
      if (this.syncOffline$.getValue()) {
        await this.sendImageOffline();
      }
    }, 10000);
  }
}
