import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Config} from '@ionic/angular';

import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
export const USER_DATA = '_cogmo_user_data';
export const TOKEN_AUTH = '_cogmo_tokenAuthorization';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    baseUrl = environment.apiUrl;
    public userData$: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(private http: HttpClient) {}

    login(user: any) {
        return this.http.post<Config>(`${this.baseUrl}/login`, user).toPromise();
    }

    currentUser() {
      return this.http.get<Config>(`${this.baseUrl}/current-user`).toPromise();
    }

    getAuthorizationToken(): string {
        return localStorage.getItem(TOKEN_AUTH);
    }

    clearAuthorizationToken(): void {
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(TOKEN_AUTH);
        this.removeUserData();
    }

    setAuthorizationToken(token: string): void {
        localStorage.setItem(TOKEN_AUTH, token);
    }

    getUserData(): any {
        const userData = localStorage.getItem(USER_DATA);
        return JSON.parse(userData);
    }

    getUser(): Observable<any> {
        this.userData$.next(this.getUserData());
        return this.userData$;
    }

    setUserData(data: object): void {
        if (data) {
            const userData = JSON.stringify(data);
            localStorage.setItem(USER_DATA, userData);
            this.userData$.next(data);
        }
    }

    removeUserData(): void {
        this.userData$.next({});
        localStorage.removeItem(USER_DATA);
    }

    isLogged(): boolean {
        return !!localStorage.getItem(TOKEN_AUTH);
    }
}
