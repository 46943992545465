import { ViewImageComponent } from './components/view-image/view-image.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ItemGalleryComponent } from './components/item-gallery/item-gallery.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HeaderDefaultComponent } from './components/header-default/header-default.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { FilterComponent } from './components/filter/filter.component';
import { CalendarModule } from 'ion2-calendar';
import { MbscModule } from '@mobiscroll/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { SearchAutoCompleteComponent } from './components/search-auto-complete/search-auto-complete.component';


@NgModule({
  declarations: [
    HeaderDefaultComponent,
    ItemGalleryComponent,
    SideMenuComponent,
    ViewImageComponent,
    FilterComponent,
    SearchAutoCompleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MbscModule,

    // Material Design
    MatDialogModule,
    MatListModule,
    CalendarModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,

    // Components
    HeaderDefaultComponent,
    ItemGalleryComponent,
    SideMenuComponent,
    ViewImageComponent,
    FilterComponent,
    SearchAutoCompleteComponent
  ]
})
export class SharedModule { }
