/* eslint-disable quote-props */
/* eslint-disable jsdoc/check-alignment */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable prefer-const */
import { FormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
import { enterAnimation } from './shared/components/animations/nav-animation';
import { SharedModule } from './shared/shared.module';
import { NgModule, Injectable } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizationInterceptor } from './shared/interceptors/authorization.interceptor';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import * as Hammer from 'hammerjs';

// Publicar na web o cogmo

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    // pan: {
    //   direction: Hammer.DIRECTION_ALL
    // },
    // swipe: { direction: Hammer.DIRECTION_ALL },
  };
  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: "auto",
    });
    return mc;
  }
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    FormsModule,
    MbscModule,
    SharedModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      navAnimation: enterAnimation
    }),

    AppRoutingModule,
    BrowserAnimationsModule,
    HammerModule
  ],
  providers: [
    SQLite,
    InAppBrowser,
    SQLitePorter,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    }
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule]
})
export class AppModule { }
