/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';
import { NavigationEnd, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { ImageService } from './shared/services/image.service';
import { SqliteService } from './shared/services/sqlite.service';
import { AuthService } from './shared/services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @ViewChild('sideMenuComponent') sideMenuComponent: SideMenuComponent;
  currentRoute = this.router.url;

  isDesktop: boolean;

  urlsDisableMenu = [
    '/login',
    '/sign-up',
  ];

  constructor(
    private router: Router,
    private menuController: MenuController,
    private imageService: ImageService,
    private sqliteService: SqliteService,
    private platform: Platform,
    private authService: AuthService
  ) {
    this.checkToken();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
        this.managerUrlDisable();
      }
    });
    this.platform.ready().then(() => {
      this.initDataBase();
    });
  }

  async checkToken() {
    const token = this.authService.getAuthorizationToken();
    if (token) {
      await this.router.navigateByUrl('/gallery');
    } else {
      await this.router.navigateByUrl('/login');
    }
  }

  async managerUrlDisable() {
    await this.sideMenuComponent.loadMenu();
    const menu = this.urlsDisableMenu.filter(p => p === this.currentRoute)[0];
    if (menu) {
      await this.menuController.enable(false, 'main-menu');
    } else {
      await this.menuController.enable(true, 'main-menu');
    }
  }

  async initDataBase() {
    await this.sqliteService.init();
    this.imageService.startSynchronizationJob();
  }
}
