import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: any = null;

  constructor(public loadingController: LoadingController) {
  }

  async presentLoading(message = 'Loading data', duration = 5000) {
    this.loading = await this.loadingController.create({
      message,
      animated: true,
      duration,
      showBackdrop: true,
      keyboardClose: false,

    });
    await this.loading.present();
  }

  async dismiss() {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }
}
