import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    private baseUrl = environment.apiUrl ? environment.apiUrl : null;

    constructor(protected httpClient: HttpClient) {
        if (this.baseUrl === null) {
            console.log('Atenção: environment.apiUrl não definida');
        }
    }

    protected get(endpoint: string, baseUrl: string = null) {
        let url = this.baseUrl + endpoint;
        if (baseUrl) {
            url = baseUrl + endpoint;
        }
        return this.httpClient.get<any>(this.baseUrl + '/' + endpoint);
    }

    protected post(endpoint: string, parametro: any, baseUrl: string = null) {
        let url = this.baseUrl + endpoint;
        if (baseUrl) {
            url = baseUrl + endpoint;
        }
        return this.httpClient.post<any>(this.baseUrl + '/' + endpoint, parametro);
    }

    protected postXWwwForm(endpoint: string, body: any, baseUrl: string = null) {
        let url = this.baseUrl + endpoint;
        if (baseUrl) {
            url = baseUrl + endpoint;
        }
        return this.httpClient.post(url, body.toString(), {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
        });
    }

    protected delete(endpoint: string, parametro: any, baseUrl: string = null) {
        let url = this.baseUrl + endpoint;
        if (baseUrl) {
            url = baseUrl + endpoint;
        }
        return this.httpClient.delete<any>(this.baseUrl + '/' + endpoint, parametro);
    }

    protected put(endpoint: string, parametro: any, baseUrl: string = null) {
        let url = this.baseUrl + endpoint;
        if (baseUrl) {
            url = baseUrl + endpoint;
        }
        return this.httpClient.put<any>(this.baseUrl + '/' + endpoint, parametro);
    }
}
